import { Controller } from '@hotwired/stimulus';
import { createGrid } from 'ag-grid-community'

export default class extends Controller {
  static get targets() {
    return ['table'];
  }

  static values = {
    column: String,
    columnOptions: String,
    row: String,
  };

  connect() {
    this.columnOptions = JSON.parse(this.columnOptionsValue);
    this.columnDefs = this.getColumnDefs();
    this.gridOptions = this.getGridOptions();
    this.gridApi = undefined;

    const agGridElement = this.tableTarget;
    this.gridApi = createGrid(agGridElement, this.gridOptions);
  }

  disconnect() {
    this.gridApi.destroy();
  }

  getColumnDefs() {
    return [
      {
        headerName: 'Evidence and details',
        colId: 'evidence_and_details',
        field: 'evidence_and_details',
        minWidth: 300,
        sortable: false,
        cellRenderer: (params) => {
          if (this.no_link_title(params)) {
            return params.data.evidence_and_details.title;
          } else {
            const eDiv = document.createElement('div');
            eDiv.innerHTML = `<a href="${params.data.evidence_and_details.url}" target="blank" class="">${params.data.evidence_and_details.title}</a>`;
            return eDiv;
          }
        },
      },
      {
        headerName: 'Associated products',
        field: 'associated_products',
        hide: this.columnOptions.hidden_columns && this.columnOptions.hidden_columns.includes('associated_products'),
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          if (params.data.associated_products.show_link) {
            eDiv.innerHTML = `${params.data.associated_products.link}`;
          } else {
            eDiv.innerHTML = `${params.data.associated_products.title}`;
          }
          return eDiv;
        },
      },
      {
        headerName: 'Created date',
        field: 'created_at',
      },
      {
        headerName: 'State',
        field: 'state',
        minWidth: 120,
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          eDiv.innerHTML = `${params.data.state}`;
          return eDiv;
        },
      },
      {
        headerName: '',
        field: 'actions',
        editable: false,
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          eDiv.classList.add('d-flex', 'flex-justify-end', 'flex-items-center');
          eDiv.innerHTML = `
            <div>${params.data.actions.edit.button}</div>
          `;
          return eDiv;
        },
      },
    ];
  }

  no_link_title(params) {
    const title = params.data.evidence_and_details.title;
    return title.startsWith("sub_") || title === "Evidence Needed" || title.includes("(not signed)");
  }

  getGridOptions() {
    return {
      rowData: JSON.parse(this.rowValue),
      columnDefs: this.columnDefs,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressServerSideFullWidthLoadingRow: true,
      groupDisplayType: 'custom',
      domLayout: 'autoHeight',
      pagination: true,
      defaultColDef: {
        flex: 1,
      },
      paginationPageSize: 5,
    };
  }
}
