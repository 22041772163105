import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['submit', 'activeCount', 'limit']
  }

  countChecked () {
    console.log('countChecked')
    const count = document.querySelectorAll('.countable:checked').length
    this.setActiveCount(count)
    this.limitCheck(count)
  }

  setActiveCount (count) {
    this.activeCountTarget.innerText = count
  }

  limitCheck (count) {
    const limitElement = this.limitTarget

    if (limitElement) {
      const limit = limitElement.innerText
      const submitButton = this.submitTarget
      const activeCount = this.activeCountTarget

      if (count > limit) {
        submitButton.disabled = true
        activeCount.classList.add('ActiveProducts-limitExceeded')
      } else if (count <= limit) {
        submitButton.disabled = false
        activeCount.classList.remove('ActiveProducts-limitExceeded')
      }
    }
  }
}
