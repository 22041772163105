import { Controller } from '@hotwired/stimulus';
import t from '../../../utils/translate'
import { createGrid } from 'ag-grid-community'

export default class extends Controller {
  static get targets() {
    return ['table'];
  }

  static values = {
    column: String,
    columnOptions: String,
    row: String,
  };

  connect() {
    this.columnOptions = JSON.parse(this.columnOptionsValue);
    this.columnDefs = this.getColumnDefs();
    this.gridOptions = this.getGridOptions();
    this.gridApi = undefined;

    const agGridElement = this.tableTarget;
    this.gridApi = createGrid(agGridElement, this.gridOptions);
  }

  disconnect() {
    this.gridApi.destroy();
  }

  getColumnDefs() {
    return [
      {
        headerName: 'Proof Point',
        colId: 'proof_point',
        field: 'proof_point',
        minWidth: 300,
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          eDiv.classList.add('d-flex', 'align-items-center');
          eDiv.innerHTML = `<div class="mt-1">${params.data.proof_point.icon}</div><div class="DataTable__proofPointName pl-2">${params.data.proof_point.name}</div>`;
          return eDiv;
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        maxWidth: 120,
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          eDiv.innerHTML = `${params.data.status.state_tag}${params.data.status.freemium_link}`;
          return eDiv;
        },
      },
      {
        headerName: 'Proof Point level',
        field: 'proof_point_level',
        wrapText: true,
        autoHeight: true,
        maxWidth: 140,
        headerComponentParams: { template: '<span data-ref="eText" class="ag-header-cell-text">Proof Point level</span>' },
        headerTooltip: t('data_table.js.proof_point_level_tooltip'),
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          eDiv.classList.add('d-flex', 'flex-column', 'w-fit', 'flex-items-start', 'flex-justify-around');
          let innerHTML = '';
          if (params.data.proof_point_level.product.show) {
            let padding = (params.data.proof_point_level.brand.show) ? '' : 'pt-2'
            innerHTML += `<div class="DataTable__proofPointLabel w-fit ${padding}">${params.data.proof_point_level.product.label}</div>`;
          }

          if (params.data.proof_point_level.brand.show) {
            let padding = (params.data.proof_point_level.product.show) ? 'pt-1' : 'pt-2'
            innerHTML += `<div class="DataTable__proofPointLabel w-fit ${padding} mb-2">${params.data.proof_point_level.brand.label}</div>`;
          }

          eDiv.innerHTML = innerHTML;
          return eDiv;
        },
      },
      {
        headerName: 'Product coverage',
        field: 'product_coverage',
        maxWidth: 250,
        headerComponentParams: { template: '<span data-ref="eText" class="ag-header-cell-text">Product coverage</span>' },
        headerTooltip: 'The number of products and percentage of your total products with this Proof Point',
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          eDiv.classList.add('d-flex', 'w-fit', 'flex-items-center', 'DataTable__proofPointProductCoverage');
          eDiv.innerHTML = `${params.data.product_coverage}`;
          return eDiv;
        },
      },
      {
        headerName: '',
        field: 'actions',
        editable: false,
        maxWidth: 150,
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          eDiv.classList.add('d-flex', 'flex-justify-center', 'flex-items-center');
          eDiv.innerHTML = `
            <div>${params.data.actions}</div>
          `;
          return eDiv;
        },
      },
    ];
  }

  getGridOptions() {
    return {
      rowData: JSON.parse(this.rowValue),
      columnDefs: this.columnDefs,
      rowClass: "py-2",
      rowHeight: 70,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressServerSideFullWidthLoadingRow: true,
      groupDisplayType: 'custom',
      domLayout: 'autoHeight',
      suppressCellFocus: true,
      defaultColDef: {
        flex: 1,
      }
    };
  }
}
